import './style.scss';

let askForms = document.querySelectorAll('.form--ask')

askForms.forEach( ( form ) => {
  form.addEventListener('submit', function (e) {
    e.preventDefault()
  
    let formData = $(this).serialize()

    if (_wpObject.ajaxUrl) {
      $.ajax({
        type: 'POST',
        url: _wpObject.ajaxUrl,
        data: 'action=send_form_ask&' + formData,
        dataType: 'JSON',
        success: function (response) {
          if (response['code'] == 1) {
            alert(response['error'])
          } else {
            alert(response['error'])
          }
        }
      });
    }
  })
})

function isValid(el) {
  let $phone = el.find('input[name="phone"]');
  let $field = $phone.parent();
  let $error = $phone.next('.error');
  if (!$error.length) {
    $phone.after('<span class="error"></span>');
    $error = $phone.next('.error');
  }
  let phoneVal = $phone.val()
  if (isValidPhone(phoneVal)) {
    $error.text('');
    $field.removeClass('form__field--with-error');
    return true
  } else {
    $field.addClass('form__field--with-error');
    $error.text('Please, fill correct phone number');
    return false
  }
}

function isValidPhone(phone) {
  let pattern = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
  return pattern.test(phone);

 /* Valid formats:
  (123) 456-7890
  (123)456-7890
  123-456-7890
  123.456.7890
  1234567890
  +31636363634
  075-63546725 */
}