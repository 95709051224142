import DropZone from './dragdrop';

export default class MultipleEntries {
  counter = 1 // Int
  form        // DOM element or selector
  addButton   // DOM element or selector
  list        // DOM element or selector
  template

  constructor(options) {
    if ( options.form == null ) return console.error('MultipleEntries plugin: Please add form!')

    this.form      = options.form
    
    this.initList()

    this.initAddButton()

    this.initTemplate()
  }

  initList() {
    this.list = this.form.querySelector('.multiple-entries__list')
  }

  initTemplate() {
    let node = this.form.parentElement.querySelector('.multiple-entries__template')

    this.template = node.innerHTML
  }

  initAddButton(button) {
    if( (typeof button) == 'string') {
      this.addButton = this.form.querySelector(options.addButton)
    } else {
      if ( isElement(button) ) {
        this.addButton = button
      } else {
        this.addButton = this.form.querySelector('.multiple-entries__add')
      }
    }

    if (isElement(this.addButton)) {
      this.addButton.addEventListener('click', (e) => {
        e.preventDefault()

        this.incraseCounter()

        if (this.counter < 10 ) {
          let event = new Event('MultipleEntriesAppend')

          this.appendCloned()

          let row          = this.form.querySelector(`.multiple-entries__item-${this.counter}`)
          let fishSelect   = row.querySelector('select[name="kind_of_fish"]')
          let dateInput    = row.querySelector('input[name="date_caught"]')
          let waterSelect  = row.querySelector('select[name="water_where_caught"]')
          let customWInput = row.querySelector('input[name="custom_water_caught"]')
          let countySelect = row.querySelector('select[name="county_where_caught"]')
          let remove       = row.querySelector('.multiple-entries__remove')
          let imgLabel     = row.querySelector('.image-upload>label')
          let imgInput     = row.querySelector('input[type="file"]')
          // let title      = row.querySelector('h6')

          remove.addEventListener('click', (e) => {
            row.remove()
          })

          // title.innerHTML += ` #${this.counter}`

          fishSelect.setAttribute('name', `kind_of_fish-${this.counter}`)
          dateInput.setAttribute('name', `date_caught-${this.counter}`)
          waterSelect.setAttribute('name', `water_where_caught-${this.counter}`)
          customWInput.setAttribute('name', `custom_water_caught-${this.counter}`)
          countySelect.setAttribute('name', `county_where_caught-${this.counter}`)
          imgInput.setAttribute('id', `slam-files-${this.counter}`)
          imgInput.setAttribute('name', `images-${this.counter}[]`)
          imgLabel.setAttribute('for', `slam-files-${this.counter}`)

          new NativejsSelect({
            selector: `.custom-select.cloned`, //-${this.counter}
            enableSearch: true
          });

          document.dispatchEvent(event)
        }

        
      })
    }
  }

  incraseCounter() {
    this.counter = ++this.counter
  }

  decreaseCounter() {
    this.counter = --this.counter
  }

  appendCloned() {
    let row = document.createElement('div')
    row.classList.add(`multiple-entries__item-${this.counter}`)
    row.innerHTML = this.template

    this.list.append(row)

    new DropZone(row.querySelector('.drop-zone'));
  }
  //static incraseCounter() {
  //   this.counter = ++this.counter
  // }

  // get counter() {
  //   return this.counter
  // }
}

function isElement(obj) {
  try {
    //Using W3 DOM2 (works for FF, Opera and Chrome)
    return obj instanceof HTMLElement;
  }
  catch(e){
    //Browsers not supporting W3 DOM2 don't have HTMLElement and
    //an exception is thrown and we end up here. Testing some
    //properties that all elements have (works on IE7)
    return (typeof obj==="object") &&
      (obj.nodeType===1) && (typeof obj.style === "object") &&
      (typeof obj.ownerDocument ==="object");
  }
}