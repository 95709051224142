export default function uploadFile (input) {
  let parent = input.closest('.form__field.image-upload')
  let title  = parent.querySelector('.image-upload__label')
  let files  = input.files

  // let fileList   = parent.querySelector('.image-upload__files')
  // let fileListLi = fileList.querySelectorAll('li')
  // fileList.innerHTML = ''
  
  // if (fileListLi.length > 0) {
  //   title.innerHTML = 'Upload Your Photo'
  // } else {
  //   title.innerHTML = 'Choose another photos'
  // }

  for (let i = 0; i < files.length; i++ ) {
    let fileError  = document.createElement('span')
    let file       = files[i]

    if (file) {
      if ( file.size > 3145728  ) {
        alert(`File "${file.name}" is too big! Max file size is 3MB.`);
        title.innerHTML = 'Upload Your Photo*'
        fileError.classList.add('error')

        // fileError.textContent = `${file.name} size is too big!`
        // fileUpload.after(fileError)
      } else {
        title.innerHTML = `${file.name}`
        // let li = document.createElement('li')
        // li.innerHTML = `<span>${file.name}</span>`
        // fileList.appendChild(li)
      }
    }
  }
}