
class DragDropZone {
  constructor ( element ) {
    this.files       = [];
    this.element     = element;
    this.input       = this.element.querySelector('input[type="file"]');
    this.placeholder = this.element.querySelector('.drop-zone__placeholder');
    this.list        = this.element.querySelector('.drop-zone__list');

    this.element.addEventListener('dragover', (e) => this.dragOverHandler(e) );
    this.element.addEventListener('drop', (e) => this.dropHandler(e) );
    this.input.addEventListener('change', (e) => this.changeHandler(e) );
  }

  dragOverHandler(e) {
    e.preventDefault();
  }

  dropHandler(e) {
    e.preventDefault();

    let newFiles = Object.values(e.dataTransfer.files);

    this.updateFilesList(newFiles);
  }

  changeHandler(e) {
    e.preventDefault();
    
    let newFiles = Object.values(e.target.files);

    this.updateFilesList(newFiles);
  }

  updateFilesList( files ) {
    if (files) {
      this.files = this.files.concat(files);
    }

    if (this.files.length > 5) {
      alert('You can upload only 5 files!');
      this.files = this.files.slice(0, 5);
    }
    
    const dataTransfer = new DataTransfer();
    
    [...this.files].forEach( (file, index) => {
      if ( file.size > 4194304 ) {
        alert(`File "${file.name}" is too big!\nMax file size is 4MB.`);

        this.files.splice(index, 1);

        return;
      }

      dataTransfer.items.add(file);
    });
    
    this.input.files = dataTransfer.files;
    
    this.updatePlaceholder();

    this.loopListItems()
  }

  updatePlaceholder () {
    if ( this.files.length > 0 ) {
      this.placeholder.style.display = 'none';
    } else {
      this.placeholder.style.display = 'flex';
    }
  }

  renderListItem( file, i ) {
    let tmppath = URL.createObjectURL(file);
    let img     = document.createElement('img');
    let figure  = document.createElement('figure');
    let remove  = document.createElement('div');

    img.src = tmppath;
    img.alt = file.name;
    remove.classList.add('drop-zone__remove');
    
    remove.addEventListener( 'click', (e) => this.removeListItem(e) );

    figure.appendChild(img);
    figure.appendChild(remove);

    this.list.appendChild(figure);
  }

  removeListItem( e ) {
    e.preventDefault();

    let nodes = this.list.children;
    let parent = e.target.closest('figure');
    let index = Array.prototype.indexOf.call(nodes, parent);
    
    this.files.splice(index, 1);
    this.updateFilesList(false);
  }

  loopListItems() {
    this.list.innerHTML = '';

    [...this.input.files].forEach( (file, i) => {
      this.renderListItem( file, i );
    } );
  }

  reset () {
    this.files = [];
    this.updateFilesList(false);
  }
}

export default DragDropZone;
