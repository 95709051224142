import './style.scss';

document.addEventListener('DOMContentLoaded', () => {
  updateAccorionState();
  updateAccorionsStyles();
})

window.addEventListener('hashchange', function () {
  updateAccorionState();
});

let updateAccorionState = () => {
  let hash = window.location.hash;
  
  if (hash) {
    let el = document.querySelector( hash + ' > details')

    el.setAttribute('open', '');
  }
}

let updateAccorionsStyles = () => {
  let items = document.querySelectorAll(`.accordion__item`);
  
  items.forEach(el => {
    let color = el.getAttribute('data-bg');
    
    el.style.setProperty('--color', color);
  });
}