import './style.scss';

let searchForm  = document.querySelector('#species-search')
let speciesList = document.querySelector('#species-list')
let pagination  = document.querySelector('#species_pagination')

if ( searchForm ) searchForm.addEventListener( 'submit', (e) => formSubmit(e) )
if ( pagination ) pagination.addEventListener( 'click', (e) => getPage(e) )

let formSubmit = ( e ) => {
  e.preventDefault()
  let args = getFormData()

  speciesQuery( args )
}

let getPage = ( e ) => {
  e.preventDefault()
  let that = e.target
  let page = that.getAttribute('page-index')

  if ( that.classList.contains('pagination__item') && page != '…' ) {
    let args = getFormData(page)
    
    speciesQuery( args )
  }
}

let speciesQuery = ( args ) => {
  $.ajax({
    url: '/wp-admin/admin-ajax.php',
    dataType: 'json',
    type: 'POST',
    data: {
      'args' : args,
      'action': 'species_search'
    },
    beforeSend: () => {
      speciesList.scrollIntoView({ behavior: 'smooth' });
      speciesList.innerHTML = '<div class="tac" style="width: 100%;padding:30px;color:#e58947;font-size:20px;">Loading...</div>'
    },
    success: function(response) {
      speciesList.innerHTML = response['html']
      pagination.innerHTML  = response['pagination']
    }
  });
}

let getFormData = ( paged ) => {
  paged = paged ? paged : 1
  let data   = new FormData( searchForm )
  let terms  = Object.fromEntries( data.entries() )
  let tax    = Object.keys(terms)
  let args = {
    post_type      : 'species',
    paged          : paged,
    posts_per_page : 9,
    orderby        : 'name',
    order          : 'ASC',
    tax_query      : []
  }

  tax.map( key => {
    if(terms[key] > 0) {

      let tax_obj = {
        taxonomy : key,
        field    : 'term_id',
        terms    : terms[key],
        operator : 'and',
      }

      args.tax_query.push(tax_obj)
    } else if ( key === 'keyword') {
      args.s = terms[key]
    }
  } )

  return args
}