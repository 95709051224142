import './style.scss';
import MultipleEntries from './multiple-entries'
import uploadFile from './upload-file'
import DropZone from './dragdrop';

let entryForms = document.querySelectorAll('.form--entry')

if ( entryForms.length > 0 ) {
  entryForms.forEach( ( form ) => {
    let cid = form.querySelector('input[name="client_number"]')
    let dob = form.querySelector('input[name="date_of_birth"]')
    let wwc = form.querySelector('select.custom-select--water')
    let mea = form.querySelector('.multiple-entries__add')
    let ufs = form.querySelectorAll('.upload_image')
    let dpz = new DropZone(form.querySelector('.drop-zone'))
    let tfw = form.querySelector('#trophy-weight-input')

    if (tfw) {
      tfw.addEventListener('blur', (e) => {
        let min = tfw.getAttribute('min')
        if ( min > 0 ) {
          let error = new Error(e.target)
          if (e.target.value >= min) {
            if (tfw.nextElementSibling) {
              tfw.nextElementSibling.style.display = 'inline-block';
            }
            error.remove()
          } else {
            if (tfw.nextElementSibling) {
              tfw.nextElementSibling.style.display = 'none';
            }
            error.setMessage('Enter a valid weight! The minimum weight is ' + min + ' lbs.')
            error.render()
          }
        }
      })
    }
    
    wwc.addEventListener('change', (e) => {
      let cwwc = form.querySelector('.form__field--custom-water')
      if (e.target.value == 'custom') {
        cwwc.classList.add('active')
      } else {
        cwwc.classList.remove('active')
      }
    })

    cid.addEventListener('blur', (e) => {
      twoFieldsValidate( e.target, dob)
    })

    dob.addEventListener('blur', (e) => {
      twoFieldsValidate( e.target, cid)
    })

    if (mea) {

      new MultipleEntries({
        form : form
      });

      document.addEventListener('MultipleEntriesAppend', (e) => {
        initDateMask('.datemask.cloned') 

        form.querySelectorAll('.upload_image.cloned').forEach( input => {
          input.onchange = (e) => {
            uploadFile(input, e.target.files )
          }
        })

        let wwcc = form.querySelector('select.custom-select--water.cloned')
    
        wwcc.addEventListener('change', (e) => {
          let cwwc = form.querySelector('.form__field--custom-water.cloned')
          if (e.target.value == 'custom') {
            cwwc.classList.add('active')
          } else {
            cwwc.classList.remove('active')
          }
        })
      })
    }

    ufs.forEach( input => {
      input.onchange = (e) => {
        uploadFile( e.target )
      }
    })

    form.addEventListener('submit', function (e) {
      e.preventDefault()
      
      let btn = form.querySelector('button.cta')

      if (formValidate(form)) { 
        if (_wpObject.ajaxUrl) {
          $.ajax({
            url: _wpObject.ajaxUrl,
            type: 'POST',
            contentType: false,
            processData: false,
            dataType: 'JSON',
            data: new FormData(this),
            beforeSend: (jXHR) => {
              btn.innerHTML = 'Sending...'
              btn.disabled  = true
            },
            success: function (response) {
              if (response['code'] == 1) {
                alert(response['error'])
              } else {
                alert(response['error'])
              }
              btn.innerHTML = 'Send'
              btn.disabled  = false

              form.reset()
              dpz.reset();
            }
          });
        }
      }
    } )
  })

  let twoFieldsValidate = ( field1, field2 ) => {
    let error          = new Error(field2)
    let placeholder    = field2.getAttribute('placeholder').replace('*' , '')

    if ( field1.value == '' ) {
      field2.setAttribute('placeholder', placeholder + '*')
      field2.setAttribute('required', '')
    } else {
      error.remove()
      field2.setAttribute('placeholder', placeholder)
      field2.removeAttribute('required')
    }
  }

  let formValidate = ( form ) => {
    let inputs  = form.querySelectorAll('input[required]')
    let selects = form.querySelectorAll('select[required]')
    let files   = form.querySelectorAll('input[type="file"]')
    let errors  = []

    selects.forEach( (el) => {
      errors.push(validate(el))
    } )
    inputs.forEach( (el) => {
      errors.push(validate(el))
    } )
    files.forEach( (el) => {
      errors.push(validate(el))
    } )

    scroll({
      top: form.offsetTop - 70,
      behavior: "smooth"
    })

    return errors.every( el => el === true ) ? true : false
    
  } 

  let validate = ( el ) => {
    let error = new Error(el)
    
    if (el.value === undefined || '' || null || el.value == 0) {
      error.setMessage('This field is required!')
      error.render()

      el.addEventListener('change', (e) => {
        if ( e.target.value ) {
          validate(e.target)
        }
      })

      return false
    // } else if ( el.name == 'date_of_birth' && !error.isDate() ) {
    //   error.remove()
    //   error.setMessage('Enter a valid date!')
    //   error.render()
    } else if ( el.name == 'phone' && !error.isPhone() ) {
      error.remove()
      error.setMessage('Enter a valid phone number!')
      error.render()

      return false
    } else if ( el.name == 'email' && !error.isEmail() ) {
      error.remove()
      error.setMessage('Enter a valid email address!')
      error.render()

      return false
    } else {
      error.remove()

      return true
    }
  }

  class Error {
    constructor( field ) {
      this.field  = field
      this.parent = field.parentElement
      this.error  = document.createElement('span')
      this.error.classList.add('error')
    }

    setMessage( text ) {
      this.message = text
      this.error.textContent = this.message
    }

    render() {
      if ( this.exists() == false ) {
        this.parent.classList.add('form__field--with-error')
        this.parent.appendChild(this.error)
      }
    }

    remove() {
      if ( this.exists() == true ) {
        this.parent.removeChild(this.parent.querySelector('.error'))
        this.parent.classList.remove('form__field--with-error')
      }
    }

    exists() {
      if ( this.parent.querySelector('.error') == null ) {
        return false
      } else {
        return true
      }
    }

    isPhone() {
      let pattern = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)
      return pattern.test(this.field.value)
    }

    isEmail() {
      let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      return pattern.test(this.field.value)
    }

    isDate() {
      let pattern = new RegExp(/^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$/)
      return pattern.test(this.field.value)
    }
  }

  let checkDate = (e, elm) => {
    if(e.keyCode < 47 || e.keyCode > 57) {
      e.preventDefault()
    }

    var len   = elm.value.length
    // let array = elm.value.split('/')
    // let year  = new Date().getFullYear()
    // console.log(array);

    // switch (array.length) {
    //   case 1 :
    //     if (elm.value > 12) {
    //       elm.value = 12
    //     }
    //   case 2 :
    //     if (array[1] != undefined) {
    //       if (array[0] > 12) {
    //         elm.value = '12/' + array[1]
    //       }
    //       if (array[1] > 31) {
    //         elm.value = array[0] + '/31'
    //       }
    //     }
    //   case 3 :
    //     if (array[2] != undefined) {
          
    //       if (array[0] > 12) {
    //         elm.value = '12/' + array[1]
    //       }

    //       if (array[1] > 31) {
    //         elm.value = array[0] + '/31/' + array[2]

    //         checkDate(e, elm)
    //       }

    //       if(array[2].length ===3){
    //         if (array[2] + e.key > year) {
    //           elm.value = array[0] + '/' + array[1] + '/' + year
    //           e.preventDefault()
    //         }
    //       }
    //     }
    // }

    if(len !== 1 || len !== 3) {
      if(e.keyCode == 47) {
        e.preventDefault()
      }
    }
    
    if(len === 2) {
      elm.value += '/'
    }

    if(len === 5) {
      elm.value += '/'
    }


    if(len===10){
      e.preventDefault()
    }
  }

  let dateInputMask = (elm) => {
    elm.addEventListener('keypress', e => checkDate(e, elm))
  }  
  
  let initDateMask = (selector) => {
    document.querySelectorAll(selector).forEach( el => dateInputMask(el) )
  }

  initDateMask('.datemask')

}